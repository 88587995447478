
.side-panel {
    position: fixed;
    top: $header-height;
    right: 0;
    display: none;
    flex-direction: column;
    width: $side-panel-width;
    height: calc(100vh - #{$header-height});
    background-color: $gray-base-90;
    box-shadow: 0 2px 4px 0 rgba(#000, .5);
    z-index: $zindex-fixed;
    &.open {
        display: flex;
    }

    ycn-guided-workflows-steps p {
        margin-bottom: 0;
    }
}

.side-panel-header {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 10px $side-panel-padding;
}

.side-panel-title {
    @include headerMedium();
    display: flex;
    align-items: center;

    .btn {
        fill: $gray-base-40;
        &:hover {
            fill: $yc-blue;
        }
    }
}

.side-panel-toggler {
    cursor: pointer;
    fill: $gray-base-50;
    &:hover {
        background-color: $gray-base-80;
        border-color: $gray-base-80;
    }
}


.side-panel-content {
    position: relative;
    flex: auto;
    display: flex;
    flex-direction: column;
    background-color: $side-panel-body-background;
    overflow: auto;

    .nav {
        background-color: $gray-base-80;
    }

    .nav-link.active {
        background-color: $side-panel-body-background;
    }

    .tab-content {
        display: flex;
        flex-direction: column;
        min-height: 0;
        max-height: calc(100vh - #{$base-height} * 2);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .tab-pane {
        padding-left: 0;
        padding-right: 0;
        overflow: auto;
    }
}

.side-panel-section {
    padding-left: 16px;
    padding-right: 16px;

    img {
        max-width: 100%;
    }
}

.side-panel-footer {
    flex: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: $base-height;
    padding-left: $side-panel-padding;
    padding-right: $side-panel-padding;
    background-color: $gray-base-90;
    color: $gray-base-30;
}
