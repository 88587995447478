// Margin Top

.mt-auto {
    margin-top: auto;
}

.-mt-2 {
    margin-top: -$spacer * 2;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: $spacer;
}

.mt-2 {
    margin-top: $spacer * 2;
}

.mt-3 {
    margin-top: $spacer * 3;
}

.mt-4 {
    margin-top: $spacer * 4;
}

.mt-5 {
    margin-top: $spacer * 5;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

// Margin Bottom

.mb-auto {
    margin-bottom: auto;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-1 {
    margin-bottom: $spacer;
}

.mb-2 {
    margin-bottom: $spacer * 2;
}

.mb-3 {
    margin-bottom: $spacer * 3;
}

.mb-4 {
    margin-bottom: $spacer * 4;
}

.mb-5 {
    margin-bottom: $spacer * 5;
}

.mb-row {
    margin-bottom: $form-row-margin-vertical;
    @include media-breakpoint-up(lg) {
        margin-bottom: $form-row-margin-vertical-desktop;
    }
}

.-mb-4 {
    margin-right: -4px;
}

// Margin Left

.ml-auto {
    margin-left: auto;
}

.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: $spacer;
}

.ml-2 {
    margin-left: $spacer * 2;
}

.ml-10 {
    margin-left: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: $spacer * 3;
}

.ml-4 {
    margin-left: $spacer * 4;
}

.-ml-1 {
    margin-left: -1px;
}

.-ml-10 {
    margin-left: -10px;
}

// Margin Right

.mr-auto {
    margin-right: auto;
}

.mr-1 {
    margin-right: $spacer;
}

.mr-2 {
    margin-right: $spacer * 2;
}

.mr-12 {
    margin-right: 12px;
}

.mr-20 {
    margin-right: 20px;
}

.-mr-1 {
    margin-right: -1px;
}

// Height

.min-height-base {
    @include base-min-height();
}

.height-base {
    @include base-height();
}

.min-height-72 {
    min-height: 72px;
}

.min-height-220 {
    min-height: 220px;
}

.max-height-230 {
    max-height: 230px;
}

.height-full {
    height: 100%;
}

.height-6 {
    height: 6px;
}

.height-13 {
    height: 13px;
}

.height-24 {
    height: 24px;
}

.height-30 {
    height: 30px;
}

.height-34 {
    height: 34px;
}

.height-40 {
    height: 40px;
}

.height-115 {
    height: 115px;
}

.height-320 {
    height: 320px;
}


// Widths

.min-width-base {
    @include base-min-width();
}

.min-width-max-content {
    min-width: max-content;
}

.min-width-20 {
    min-width: $spacer * 20;
}

.min-width-breakpoint-md {
    min-width: map-get($grid-breakpoints, md);
}

.min-width-720 {
    min-width: 720px;
}

.width-13 {
    width: 13px;
}

.width-20 {
    width: 20px;
}

.width-24 {
    width: 24px;
}

.width-30 {
    width: 30px;
}

.width-40 {
    width: 40px;
}

.width-64 {
    width: 64px;
}

.width-90 {
    width: 90px;
}

.width-96 {
    width: 96px;
}

.width-112 {
    width: 112px;
}

.width-125 {
    width: 125px;
}

.width-140 {
    width: 140px;
}

.width-150 {
    width: 150px;
}

.width-180 {
    width: 180px;
}

.width-200 {
    width: 200px;
}

.width-240 {
    width: 240px;
}

.width-260 {
    width: 260px;
}

.width-400 {
    width: 400px;
}

.width-full {
    width: 100%;
}

.width-4\/5 {
    width: 80%;
}

.max-width-0 {
    max-width: 0;
}

.max-width-54 {
    max-width: 54px;
}

.max-width-130 {
    max-width: 130px;
}

.max-width-220 {
    max-width: 220px;
}

.max-width-400 {
    max-width: 400px;
}

.max-width-sm {
    max-width: map-get($container-max-widths, sm);
}

.max-width-lg {
    max-width: map-get($container-max-widths, lg);
}

.max-width-1200 {
    max-width: 1200px;
}

.width-auto {
    width: auto;
}

// Reset paddings

.pt-0 {
    padding-top: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}


// Custom paddings

.pt-1 {
    padding-top: $spacer;
}

.pt-10 {
    padding-top: 10px;
}

.pt-12 {
    padding-top: 12px;
}

.pt-2 {
    padding-top: $spacer * 2;
}

.pt-20 {
    padding-top: 20px;
}

.pt-3 {
    padding-top: $spacer * 3;
}

.pt-100 {
    padding-top: 100px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-60 {
    padding-top: 60px;
}

.pl-80 {
    padding-left: 80px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-12 {
    padding-right: 12px;
}

.pl-2 {
    padding-left: $spacer * 2;
}

.pr-2 {
    padding-right: $spacer * 2;
}

.pt-4px {
    padding-top: 4px;
}

.pb-4px {
    padding-bottom: 4px;
}

.pb-1 {
    padding-bottom: $spacer;
}

.pb-2 {
    padding-bottom: $spacer * 2;
}

.pl-1 {
    padding-left: $spacer;
}

.pr-1 {
    padding-right: $spacer;
}

.pr-10 {
    padding-right: 10px;
}

.pr-40 {
    padding-right: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pb-20 {
    padding-bottom: 20px;
}

.break-spaces {
    white-space: break-spaces;
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-270 {
    transform: rotate(270deg);
}
